<script>
import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      user: [],
      roles: []
    };
  },
  methods: {
    async getUsers() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/admin/user/" + this.$route.params.id
      );
      this.user = response.data;


      await axios.get('https://api.doctosoft.com/index.php/api/role').then((response) => {

        this.roles = response.data
      })
    },
    async updateUsers() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", this.user.name);
      urlencoded.append("userName", this.user.userName);
      urlencoded.append("password", this.user.password);
      urlencoded.append("role", this.user.role);

      await axios.put(
        "https://api.doctosoft.com/index.php/api/admin/user" + "/" + this.$route.params.id,
        urlencoded
      );

      this.$router.push("/admin/all-users");
    },
  },
  created() {
    this.getUsers();

  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/all-users')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/all-users')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="updateUsers">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="updateUsers">
            <div class="mb-3">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="user.name" placeholder="Enter name" />
            </div>

            <div class="mb-3">
              <label for="username" class="form-label">UserName <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="user.userName" placeholder="Enter UserName" />
            </div>

            <div class="mb-3">
              <label for="password" class="form-label">Password <span class="text-danger">*</span></label>
              <input type="password" class="form-control" v-model="user.password" placeholder="Enter password" />
            </div>
            <div class="mb-3">
              <label for="role" class="form-label">Role</label>

              <select class="form-select" aria-label="size 3 select example" v-model="user.role">
                <option v-for="role in roles" :key="role.id" :value="role.id"> {{ role.name }}</option>

              </select>

            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
